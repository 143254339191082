<template>
  <div class="fraudulence-drawer">
    <SDrawer
      class="report-drawer-container"
      no-header
      :visible="show"
      :show-close="false"
      type="multi"
      direction="btt"
      close-on-click-modal
      @close-from-mask="show = false"
    >
      <div class="fraudulence-drawer__title">
        {{ language.SHEIN_KEY_PWA_28950 }}
        <i
          class="fraudulence-drawer__close suiiconfont sui_icon_close_16px_2"
          @click="closeHandleIcon"
        ></i>
      </div>
      <div class="fraudulence-drawer__content">
        {{ language.SHEIN_KEY_PWA_28949 }}
      </div>
      <div class="fraudulence-drawer__button-container">
        <SButton
          class="fraudulence-drawer__button"
          :type="['primary', 'H72PX']"
          width="100%"
          :loading="loading"
          @click="handleAgree"
        >
          {{ language.SHEIN_KEY_PWA_28951 }}
        </SButton>
        <SButton
          class="fraudulence-drawer__button"
          width="100%"
          :type="['H72PX']"
          @click="show = false"
        >
          {{ language.SHEIN_KEY_PWA_28952 }}
        </SButton>
      </div>
    </SDrawer>
  </div>
</template>

<script setup>
import { Drawer as SDrawer, Button as SButton } from '@shein/sui-mobile'
import { computed } from 'vue'
const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  language: { type: Object, default: () => {} },
  loading: { type: Boolean, default: false }
})
const emit = defineEmits(['agree', 'input'])
// const show = ref(false)
const show = computed({
  get() {
    return props.value
  },
  set(val) {
    emit('input', val)
  }
})

const closeHandleIcon = () => {
  show.value = false
}

const handleAgree = () => {
  emit('agree')
}
</script>

<style lang="less" scoped>
.fraudulence-drawer {
  &__close {
    position: absolute;
    top: 50%;
    right: 0.32rem;
    transform: translateY(-50%);
    color: #8a8a8a;
    font-size: 0.42667rem;
  }
  &__title {
    position: relative;
    font-size: 0.42667rem;
    font-weight: 500;
    padding: 0.32rem;
    border-bottom: 0.01333rem solid #e5e5e5;
    text-align: center;
  }
  &__content {
    margin-top: 0.43rem;
    padding: 0 0.43rem;
    text-align: center;
    font-size: 0.37333rem;
  }
  &__button-container {
    margin-top: 0.83rem;
    padding: 0 1.63rem;
  }
  &__button {
    margin-bottom: 0.32rem;
  }
}
// /deep/ .S-button {
//     margin-top: 0.32rem;
//     margin-bottom: 0.21rem;
// }
/deep/ .S-drawer__container {
  border-radius: 0.16rem 0.16rem 0rem 0rem;
}
/deep/ .S-drawer__body {
  background: #fff;
}
</style>
